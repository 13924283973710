<template>
    <div class="bk-portfolio-area">
        <div class="black-bg-wrapper bg_color--3 section-pt-xl pb--240">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center white-text wow move-up">
                            <h2>Create and make your dream.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="portfolio-wrapper pl--150 pr--150 pl_lg--50 pr_lg--50 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">
            <div class="row row--0 space_dec--110">
                <!-- Start Single Portfolio -->
                <div class="col-lg-6">
                    <div class="portfolio portfolio_style--1">
                        <div class="thumb">
                            <img src="/img/portfolio/portfolio-1/hardset.jpg" alt="Portfolio Images">
                        </div>
                        <div class="port-overlay-info">
                            <div class="hover-action">
                                <h3 class="post-overlay-title">
                                    <n-link to="portfolio/portfolio-details">
                                        Custom Hard Sets
                                    </n-link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Portfolio -->

                <div class="col-lg-6">
                    <div class="row row--0">

                        <div class="col-lg-12 wow move-up">
                            <!-- Start Single Portfolio -->
                            <div class="portfolio portfolio_style--1">
                                <div class="thumb">
                                    <img src="/img/portfolio/portfolio-1/lights.jpg" alt="Portfolio Images">
                                </div>
                                <div class="port-overlay-info">
                                    <div class="hover-action">
                                        <h3 class="post-overlay-title">
                                            <n-link to="portfolio/portfolio-details">
                                                Dramatic Lighting
                                            </n-link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single Portfolio -->
                        </div>

                        <div class="col-lg-6 wow move-up">
                            <!-- Start Single Portfolio -->
                            <div class="portfolio portfolio_style--1">
                                <div class="thumb">
                                    <img src="/img/portfolio/portfolio-1/video.jpg" alt="Portfolio Images">
                                </div>
                                <div class="port-overlay-info">
                                    <div class="hover-action">
                                        <h3 class="post-overlay-title">
                                          <n-link to="portfolio/portfolio-details">
                                            Live Video
                                          </n-link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single Portfolio -->
                        </div>

                        <div class="col-lg-6 wow move-up">
                            <!-- Start Single Portfolio -->
                            <div class="portfolio portfolio_style--1">
                                <div class="thumb">
                                    <img src="/img/portfolio/portfolio-1/speakers.jpg" alt="Portfolio Images">
                                </div>
                                <div class="port-overlay-info">
                                    <div class="hover-action">
                                        <h3 class="post-overlay-title">
                                            <n-link to="portfolio/portfolio-details">
                                                Professional Audio
                                            </n-link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single Portfolio -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    };
</script>

<style lang="scss">

</style>
